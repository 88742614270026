.buttons-card {
  box-sizing: border-box;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 80px;
}

.buttons-card__list {
  list-style: none;
  padding: 0 0 45px;
  margin: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  align-content: center;
}

.buttons-card__grid {
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(80px, 1fr)
  ); /* Минимальная ширина для кнопки установлена в 180px. */
  padding: 10px;
  width: 100%;
}

.add-button {
  cursor: pointer;
  min-width: 75px;
  height: 75px;
  background-color: white;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.08);
  transition: all 0.5s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-weight: 500;
  font-size: 25px;
}

.sliderContainer {
  position: relative;
  display: block; /* убедимся, что блок занимает всю ширину */
}

.slider {
  display: block; /* убедимся, что блок занимает всю ширину */
  width: 100%; /* убедимся, что блок занимает всю ширину */
}

.sliderButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.1);
  color: white;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.sliderButton:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

.sliderButton--left {
  left: -45px; /* или другое значение для смещения от края */
}

.sliderButton--right {
  right: -45px; /* или другое значение для смещения от края */
}
