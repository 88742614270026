.login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 70px 30px;
  box-sizing: border-box;
  min-height: 100vh;
}

.login__form {
  display: flex;
  flex-direction: column;
  gap: 40px;
  position: relative;
  width: 100%;
  max-width: 396px;
  min-height: 527px;
}

.login__link {
  color: #4285f4;
  line-height: 0;
  max-width: 38px;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

.login__logo {
  height: 38px;
  transition: all 0.3s ease-in-out;
  width: 38px;
}

.login__logo:hover {
  transform: scale(0.95);
}

.login__title {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.21;
  margin: 0;
}

.login__labels-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.login__label {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  width: 100%;
  align-items: flex-start;
}

.login__label-text {
  color: #a0a0a0;
  font-size: 10px;
  font-weight: 400;
  line-height: 1.2;
}

.login__input {
  background-color: #f4f4f4;
  border-radius: 8px;
  border-style: none;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 400;
  height: 46px;
  line-height: 1.21;
  padding: 10px 0;
  text-indent: 22px;
  width: 100%
}

.login__input_error {
  color: #ee3465;
}

.login__input[type="email"] {
  font-weight: 500;
}

.login__input:focus {
  border: solid 1px #3456f3;
  outline: none;
  text-indent: 21px;
}

.login__input_error {
  color: #ee3465;
}

.login__input_error:focus {
  border: solid 1px #ee3465;
}

.login__button {
  background-color: #3456f3;
  border-radius: 3px;
  border-style: none;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.21;
  margin-top: auto;
  padding: 14px 0;
  transition: all 0.3s ease-in-out;
  width: 100%;
}

.login__button:hover {
  opacity: 0.8;
}

.login__button_disabled {
  background-color: #f4f4f4;
  color: #c2c2c2;
  cursor: default;
}

.login__button_disabled:hover {
  opacity: 1;
}

.login__support {
  bottom: -7%;
  color: #a0a0a0;
  font-size: 14px;
  font-weight: 400;
  left: 25%;
  line-height: 1.21;
  position: absolute;
  text-align: center;
}

.login__error {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #ee3465;
}

.login__error-message {
  position: absolute;
  bottom: 60px;
  left: 0;
  min-height: 12px;
}

@media screen and (max-width: 400px) {
  .login {
    padding: 56px 30px;
    justify-content: flex-start;
  }

  .login__title {
    font-size: 24px;
    line-height: 29px;
    text-align: center;
  }

  .login__form {
    gap: 50px;
    flex: 1;
  }

  .login__link-logo {
    display: flex;
    align-self: center;
  }

  .login__labels-container {
    margin-top: 30px;
    gap: 20px;
  }

  .login__button {
    font-size: 12px;
    line-height: 1.25;
  }

  .login__support {
    font-size: 12px;
    line-height: 1.25;
    bottom: -4%;
    left: 4%;
  }
}
