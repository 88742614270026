.button-card {
  width: 100%;
  height: 30px;
  transition: all 0.4s ease-in-out;
  display: flex;
  align-items: center;
  background: #232323;
  border: solid 1px #1d1d1d;
  display: flex;
  gap: 15px;
}

.button-group{
  width: 100%;
}

.button-card:hover {
  background-color: #1d1d1d;
  color: #2d8ceb;
  cursor: pointer;
  border-bottom: solid 1px #2d8ceb;
  fill: #2d8ceb;
  opacity: 1;
}

/* .button-card.hover-red:hover {
  color: #EB3A2D;
  border-bottom: solid 1px #EB3A2D;
  fill: #EB3A2D;
}

.button-card.hover-green:hover {
  color: #AFEB2D;
  border-bottom: solid 1px #AFEB2D;
  fill: #AFEB2D;
}

.button-card.hover-blue:hover {
  color: #2D8CEB;
  border-bottom: solid 1px #2D8CEB;
  fill: #2D8CEB;
} */

.button-card__icon {
  margin-left: 10px;
  display: flex;
  width: 15px;
  font-size: 15px;
  opacity: 0.5;
}

.button-card__title {
  padding: 0;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.button-card > .button-card__title {
  transition: text-indent 0.4s ease-in-out;
}

.button-card:hover > .button-card__title {
  text-indent: 5px;
}

.button-card > .button-card__icon {
  transition: opacity 0.4s ease-in-out;
}

.button-card:hover > .button-card__icon {
  opacity: 1;
}

.button-card--disabled {
  opacity: 0.5;
  pointer-events: none; /* Делает элемент неактивным для событий мыши */
}

.button-card__tags {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-left: auto;
}

.button-tag {
  width: 10px;
  height: 100%;
  cursor: pointer;
  opacity: 0.1
}

.button-tag.active {
  opacity: 1;
}

.main__button{
  margin-bottom: 10px;
}

