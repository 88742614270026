body {
  margin: auto;
  background: #232323;
  background-color: #232323;
  user-select: none;
  color: #c5c5c5;
  font-size: 12.5px;
  font-weight: 500;
  font-family: "Inter", "Arial", sans-serif;
  padding:0 10px;
  box-sizing: border-box;
  max-width: 1280px;
  min-width: 25px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
}

.scrollbar {
  overflow-x: hidden; /* Hide horizontal scrollbar */
}

/* width */
::-webkit-scrollbar {
  width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #232323;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #2D8CEB;
}
