.tags {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  position: fixed;
  padding: 0 10px;
  margin-top: 55px;
  align-items: center;
  width: 100%;
  max-width: 1280px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #232323;
  z-index: 1;
}

.tag {
  width: 100%;
  height: 10px;
  cursor: pointer;
  opacity: .1;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}

.tag:hover {
  opacity: 1;
}

.tag.active {
  opacity: 1;
}
